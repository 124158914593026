import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SOCIALS from 'data/socials/socials';
import step3 from 'assets/svgs/background/home-step-3.svg';
import bsidesBus2021 from 'assets/images/secsoc/bsides-bus-2021.png';
import {
  StepsBackdrop,
  StepBgImg,
  StepNumberBg,
  StepsContent,
  StepsSocialsWrapper,
} from './styles';

const StepThree = () => {
  return (
    <Grid md={4} sm={4} xs={12}>
      <StepsBackdrop>
        <StepBgImg src={bsidesBus2021} />
        <StepNumberBg src={step3} />
        <StepsContent>
          <Typography variant="h6" align="center">
            Join our community
          </Typography>
          <StepsSocialsWrapper>
            {SOCIALS
              .filter((socials) => ['discord', 'fbgroup']
                .includes(socials.name))
              .map((social) => (
                <a key={social.name} href={social.href} target="_blank" rel="noreferrer">
                  <img alt={social.alt} src={social.src} />
                </a>
              ))}
          </StepsSocialsWrapper>
          <Typography variant="subtitle1" align="center">
            Meet other security enthusiasts alike!
          </Typography>
        </StepsContent>
      </StepsBackdrop>
    </Grid>
  );
};

export default StepThree;
