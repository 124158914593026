import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SOCIALS from 'data/socials/socials';
import step2 from 'assets/svgs/background/home-step-2.svg';
import laptopPerson from 'assets/images/secsoc/laptop-person.jpg';
import Button from 'components/Button';
import {
  StepsBackdrop,
  StepBgImg,
  StepNumberBg,
  StepsContent,
  StepsSocialsWrapper,
  StepsBtnWrapper,
} from './styles';

const StepTwo = () => {
  return (
    <Grid md={4} sm={4} xs={12}>
      <StepsBackdrop>
        <StepBgImg src={laptopPerson} />
        <StepNumberBg src={step2} />
        <StepsContent>
          <Typography variant="h6" align="center">
            Follow Us
          </Typography>
          <StepsSocialsWrapper>
            {SOCIALS
              .filter((socials) => ['facebook', 'youtube', 'linkedin', 'instagram']
                .includes(socials.name))
              .map((social) => (
                <a key={social.name} href={social.href} target="_blank" rel="noreferrer">
                  <img alt={social.alt} src={social.src} />
                </a>
              ))}
          </StepsSocialsWrapper>
          <Typography variant="subtitle1" align="center">
            Stay up to date with the latest information on our social channels!
          </Typography>
          <StepsBtnWrapper>
            <a href="https://goo.gl/w6sbzv" target="_blank" rel="noreferrer">
              <Button>Subscribe to our Newsletter</Button>
            </a>
          </StepsBtnWrapper>
        </StepsContent>
      </StepsBackdrop>
    </Grid>
  );
};

export default StepTwo;
